import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@rebass/grid';

import Layout from 'components/layout';
import HeroSection from 'components/hero';
import Header from 'components/header';
import { ContentBox } from 'styles/boxes.css';
import { H1, FocusH1, H2 } from 'styles/headers.css';

const NotFound = () => (
  <Layout>
    <HeroSection>
      <Header />
      <Box width={0.9} m="auto">
        <FocusH1>404</FocusH1>
        <H1>When we fall</H1>
        <FocusH1>We rise again</FocusH1>
      </Box>
    </HeroSection>
    <ContentBox width={0.8} m="auto" p={[3, 4, 4, 4]}>
      <Link to="/">
        <H2>Become unstoppable with our latest offer.</H2>
      </Link>
    </ContentBox>
  </Layout>
);

export default NotFound;
